<template>
    <div class="baidu_map" :class="is_full ? 'full_map' : ''">
        <div class="full" @click="is_full = !is_full">
            <div
                class="iconfont"
                :class="[is_full ? 'iconquanping_huaban1' : 'iconquanping-02']"
            ></div>
        </div>
        <baidu-map
            class="baidu_map"
			ref="baidu_map"
            :scroll-wheel-zoom="true"
            :center="center"
            :mapClick="false"
            :zoom="zoom"
        >
            
            <bm-marker
                v-for="(item, index) in position_list"
                :key="item.id ? item.id : item.latitude + index"
                :icon="{ url: position_icon, size: { width: 40, height: 40 } }"
                :position="{ lng: item.lng, lat: item.lat }"
            ></bm-marker>

			<bm-marker
                v-for="(item, index) in clock_list"
                :key="item.id ? item.id : item.latitude + index"
                :icon="{ url: at_icon, size: { width: 40, height: 40 } }"
                :position="{ lng: item.lng, lat: item.lat }"
                @click="handler_points_click($event, item)"
            ></bm-marker>
			
			<bm-marker
                v-for="(item, index) in visit_list"
                :key="item.id ? item.id : item.latitude + index"
                :icon="{ url: visit_icon, size: { width: 40, height: 40 } }"
                :position="{ lng: item.lng, lat: item.lat }"
                @click="handler_points_click($event, item)"
            ></bm-marker>

            

            <bm-polyline
                :path="points"
                stroke-color="blue"
                :stroke-opacity="0.5"
                :stroke-weight="2"
                :editing="false"
            ></bm-polyline>
        </baidu-map>
        <ImitationDetails ref="imitation_details"></ImitationDetails>
        <AttendanceDataDetails
            ref="attendance_data_details"
        ></AttendanceDataDetails>
    </div>
</template>

<script>
let type_list = [
    {
        key: 0,
        value: '计划拜访'
    },
    {
        key: 1,
        value: '临时拜访'
    },
    {
        key: 2,
        value: '指定拜访'
    },
    {
        key: 3,
        value: '巡查'
    },
];
import ImitationDetails from '@/views/imitation/index/details'
import AttendanceDataDetails from "@/views/attendance_data/index/details";
import at_icon from '@/assets/img/icon_1.png'
import visit_icon from '@/assets/img/icon_visit.png'
import position_icon from '@/assets/img/icon_position.png'
export default {
    name: "VisitMap",
    components: {
        ImitationDetails,
        AttendanceDataDetails
    },
    props: {
        points: {
            type: Array,
            default() {
                return [];
            }
        },
        zoom: {
            type: Number,
            default: 13,
        },
        center: {
            type: Object,
            default() {
                return {
                    lng: 115.86631,
                    lat: 28.695285
                }
            }
        }
    },
    watch: {
        is_full(newValue, oldValue) {
            setTimeout(()=>{
				this.$refs.baidu_map.zoom = this.zoom + 1
			},100)
        },
    },
    data() {
        return {
            at_icon,
            is_full: false,
            visit_icon,
            position_icon,
            locus: {

            },
        }
    },
    computed: {
        clock_list() {
            let list = [];
            this.points.forEach(item => {
                if (item.type == 'attendance') list.push(item)
            })
            return list;
        },
        visit_list() {
            let list = [];
            this.points.forEach(item => {
                if (item.type == 'visit') list.push(item)
            })
            return list;
        },
        position_list() {
            let list = [];
            this.points.forEach(item => {
                if (item.type == 'position') list.push(item)
            })
            return list;
        }
    },
    methods: {
        handler_points_click({ point }, value) {
            if (value.type == 'attendance') {
                this.$refs.attendance_data_details.get_details(value);
            } else {
                this.$refs.imitation_details.get_details(value);
            }

            this.$emit('point_click', value);
        },
    }
};
</script>

<style lang="less" scoped>
.baidu_map {
    width: 100%;
    height: 100%;
    position: relative;
    &.full_map {
        position: fixed;
        left: 0;
        z-index: 999;
        height: 100% !important;
        top: 0;
    }
    .full {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconfont {
            color: #fff;
        }
    }
}
.info {
    .name {
        font-size: 16px;
    }
    .desc {
        line-height: 2;
    }
    a {
        padding-right: 15px;
    }
}
</style>
